import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['option'];

    connect() {
        if (null !== this.element.querySelector('#ctrl_70d4a448f8 input:checked')) {
            this.element.classList.add('show-all');
        }

        document.documentElement.addEventListener('turbo:submit-end', (event) => {
            if (this.element.contains(event.target) && event.detail.formSubmission.result.success) {
                this.element.classList.add('submitted');
            }
        });
    }

    toggle() {
        this.element.classList.toggle('show');
        this.element.classList.remove('submitted');
    }

    select(e) {
        const option = e.currentTarget;
        const value = option.dataset.value;
        option.classList.toggle('selected');
        this.element.querySelector('input[value="'+value+'"]').checked = option.classList.contains('selected');
        this.element.classList.remove('show-all');
        this.check();
    }

    check() {
        if (null !== this.element.querySelector('#ctrl_70d4a448f8 input:checked')) {
            this.element.classList.add('show-all');
        }
    }

    optionTargetConnected(option) {
        const value = option.dataset.value;
        
        if (this.element.querySelector('input[value="'+value+'"]').checked) {
            option.classList.add('selected');
        }
    }
}
